<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${amounts.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                dark
                fab
                x-small
                class="ml-1"
                :color="rha.rha_description_id ? 'teal darken-3' : ''"
                @click.stop="rhaDescriptionDlg"
              >
                <v-icon> mdi-format-list-bulleted-type </v-icon>
              </v-btn>
            </template>
            <span v-text="'Tipo'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                dark
                fab
                x-small
                class="ml-1"
                :color="rha.internment ? 'cyan darken-3' : ''"
                @click.stop="rhaInternmentDlg"
              >
                <v-icon> mdi-invoice-text </v-icon>
              </v-btn>
            </template>
            <span v-text="'Internamiento'" />
          </v-tooltip>
          <BtnCircle
            v-if="$store.getters.getLogin.permissions.rha_amounts.create"
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :click="openDialog"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form_data" lazy-validation>
                <v-row>
                  <v-col cols="12" xs="12" md="6">
                    <p><b>Monto Gastado:</b> {{ numberFormat(used_amount) }}</p>
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <p>
                      <b>Monto autorizado:</b>
                      {{ numberFormat(authorized_amount) }}
                    </p>
                  </v-col>
                  <v-col cols="12" xs="12" md="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Monto gastado</th>
                            <th class="text-left">Fecha de monto gastado</th>
                            <th class="text-left">Monto autorizado</th>
                            <th class="text-left">Fecha de monto autorizado</th>
                            <th class="text-left">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in amounts" :key="i">
                            <td>
                              <div v-if="item.type === 2">
                                {{ numberFormat(item.used_amount) }}
                              </div>
                            </td>
                            <td>
                              <div v-if="item.type === 2">
                                {{ item.created_at }}
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="
                                  item.type === 1 &&
                                  item.rha_amount_response_id === 1
                                "
                              >
                                {{ numberFormat(item.authorized_amount) }}
                              </div>
                              <div
                                v-if="
                                  item.type === 1 &&
                                  item.rha_amount_response_id !== 1
                                "
                              >
                                {{ item.rha_amount_response }}
                              </div>
                            </td>
                            <td>
                              <div v-if="item.type === 1">
                                {{ item.created_at }}
                              </div>
                            </td>
                            <td>
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_amounts.read
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="secondary"
                                    dark
                                    @click="viewDialog(item.id)"
                                  >
                                    <v-icon v-text="'mdi-eye'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Ver'" />
                              </v-tooltip>
                              <!-- PARCHE TEMPORAL
                               v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_amounts.update
                                " -->
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_amounts.read
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="info"
                                    dark
                                    @click="editAmount(item.id)"
                                  >
                                    <v-icon v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Editar'" />
                              </v-tooltip>
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_amounts.delete
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="error"
                                    dark
                                    @click="HandleDesactivate(item.id)"
                                  >
                                    <v-icon v-text="'mdi-delete'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Eliminar'" />
                              </v-tooltip>
                              <v-tooltip
                                left
                                v-if="
                                  item.account_status &&
                                  $store.getters.getLogin.permissions
                                    .rha_status_account.read
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    :color="
                                      item.account_status_finished
                                        ? 'brown darken-1'
                                        : 'warning'
                                    "
                                    dark
                                    @click="
                                      openSelectorStatusAccount(
                                        item.id,
                                        item.rha_status_account_id
                                          ? item.rha_status_account_id
                                          : 0
                                      )
                                    "
                                  >
                                    <v-icon v-text="'mdi-format-list-checks'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Estado de cuenta'" />
                              </v-tooltip>
                              <v-tooltip
                                left
                                v-if="
                                  item.account_status_finished &&
                                  $store.getters.getLogin.permissions
                                    .rha_status_account.read &&
                                  item.rha_status_account_total_exceptions > 0
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="success"
                                    dark
                                    :href="
                                      url_pdf +
                                      '/gastos/excluidos/' +
                                      item.rha_status_account_id
                                    "
                                    target="_blank"
                                  >
                                    <v-icon v-text="'mdi-printer'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Ver anexo de exclusiones'" />
                              </v-tooltip>
                              <v-tooltip
                                left
                                v-if="
                                  item.account_status_finished &&
                                  $store.getters.getLogin.permissions
                                    .rha_status_account.read &&
                                  item.rha_status_account_total_exceptions == 0
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="secondary"
                                    dark
                                  >
                                    <v-icon v-text="'mdi-printer'" />
                                  </v-btn>
                                </template>
                                <span
                                  v-text="
                                    'Edo. Cuenta revisado sin exclusiones'
                                  "
                                />
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog
      v-model="add_dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar dark>
          <v-btn icon dark @click="handleDialogAdd">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-text="'Agregar monto'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="handleSubmit">Aceptar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form v-on:submit.prevent ref="form_data" lazy-validation>
              <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    label="Tipo*"
                    v-model="amount.type"
                    :rules="rules.required"
                    :items="types"
                    item-value="id"
                    :item-text="(item) => item.name"
                  />
                  <v-autocomplete
                    label="Respuesta*"
                    v-model="amount.rha_amount_response_id"
                    :items="amount_responses"
                    item-value="id"
                    :item-text="(item) => item.rha_amount_response"
                    v-if="amount.type == 1"
                  />
                  <v-text-field
                    label="Monto autorizado*"
                    v-model="amount.authorized_amount"
                    type="number"
                    v-if="
                      amount.type == 1 && amount.rha_amount_response_id == 1
                    "
                  />
                  <v-text-field
                    label="Número de siniestro*"
                    v-model="amount.sinester"
                    type="text"
                    v-if="
                      amount.type == 1 && amount.rha_amount_response_id == 1
                    "
                  />
                  <v-text-field
                    label="Número de carta autorización*"
                    v-model="amount.letter_auth"
                    type="text"
                    v-if="
                      amount.type == 1 && amount.rha_amount_response_id == 1
                    "
                  />
                  <v-text-field
                    label="Monto gastado"
                    v-model="amount.used_amount"
                    type="number"
                    v-if="amount.type == 2"
                  />
                  <v-autocomplete
                    v-if="amount.type == 1 && show_cpt"
                    label="Procedimiento definitivo (CPT)"
                    v-model="amount.cpt_id"
                    :items="cpts"
                    item-value="id"
                    :item-text="(v) => `${v.code} | ${v.cpt}`"
                    :loading="cpts_loading"
                  />
                  <v-autocomplete
                    v-if="amount.type == 1 && show_icd"
                    label="Diagnostico definitivo (ICD)"
                    v-model="amount.icd_id"
                    :items="icds"
                    item-value="id"
                    :item-text="(v) => `${v.code} | ${v.icd}`"
                    :loading="icds_loading"
                  />
                  <v-divider></v-divider>
                  <h5>Documentos</h5>
                  <BtnCircle
                    tLabel="Agregrar documento"
                    tPosition="bottom"
                    icon="mdi-plus"
                    color="warning"
                    size="x-small"
                    :click="addDocumento"
                    :loading="loading"
                  />
                  <div v-for="(documen, i) in amount.documents" :key="i">
                    <v-autocomplete
                      label="Documento*"
                      v-model="amount.documents[i].document_id"
                      :rules="rules.required"
                      :items="documents"
                      item-value="id"
                      :item-text="(item) => item.document"
                    />
                    <v-text-field
                      label="Descripción del documento"
                      v-model="amount.documents[i].description"
                      :rules="rules.required"
                      type="text"
                    />
                    <input
                      accept=".pdf, .docx, .doc, .txt, .xlsx, .xls"
                      label="Seleccione el documento"
                      type="file"
                      @change="select_file($event, i)"
                    /><v-btn
                      class="ma-2"
                      text
                      icon
                      size="small"
                      color="red darken-2"
                      tLabel="Quitar"
                      tPosition="bottom"
                      @click="deleteDocument(i)"
                    >
                      <v-icon medium v-text="'mdi-close'" />
                    </v-btn>
                    <v-divider></v-divider>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="view_dialog" scrollable persistent max-width="800px">
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="'Ver'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="view_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'MONTO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <ViewData
                        label="Tipo"
                        :value="
                          view_dialog
                            ? types.find((item) => item.id === amount.type).name
                            : ''
                        "
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="amount.type == 1">
                      <ViewData
                        label="Respuesta"
                        :value="
                          amount.rha_amount_response
                            ? amount.rha_amount_response.rha_amount_response
                            : ''
                        "
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="
                        amount.type == 1 && amount.rha_amount_response_id == 1
                      "
                    >
                      <ViewData
                        label="Monto autorizado"
                        :value="numberFormat(amount.authorized_amount)"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="amount.type == 2">
                      <ViewData
                        label="Monto gastado"
                        :value="numberFormat(amount.used_amount)"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="amount.type == 1">
                      <ViewData
                        label="Número de siniestro"
                        :value="rha.sinister_number"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="amount.type == 1">
                      <ViewData
                        label="Carta autorización"
                        :value="amount.letter_auth"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="amount.type == 1 && !rha.cpt_letter"
                    >
                      <ViewData
                        label="Procedimiento definitivo (CPT)"
                        :value="rha.cpt && rha.cpt.cpt"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="amount.type == 1 && rha.icd"
                    >
                      <ViewData
                        label="Diagnostico definitivo (ICD)"
                        :value="rha.icd.icd"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row
                    dense
                    v-for="(document, i) in amount.documents"
                    :key="i"
                  >
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        :label="`Tipo (${i + 1})`"
                        :value="
                          document.document ? document.document.document : ''
                        "
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData
                        label="Descripción"
                        :value="document.description"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="ml-1"
                            v-on="on"
                            fab
                            x-small
                            color="warning"
                            dark
                            :href="
                              url_documents + '/rha_amounts/' + document.url
                            "
                            target="_blank"
                          >
                            <v-icon v-text="'mdi-download'" />
                          </v-btn>
                        </template>
                        <span v-text="'Descargar'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="view_dialog_status_account_format"
      scrollable
      persistent
      max-width="800px"
    >
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="'Seleccionar formato edo. cuenta'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="view_dialog_status_account_format = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pt-3">
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                label="Formato*"
                v-model="status_account_format_id"
                :items="status_account_formats"
                item-value="id"
                :item-text="(item) => item.status_account_format"
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                v-if="status_account_format_id"
                :color="'success'"
                :to="{
                  name: `${page_route}.account_status`,
                  params: {
                    id: id,
                    amount_id: amount_id,
                    rha_status_account_id: rha_status_account_id,
                    format: status_account_format_id,
                  },
                }"
              >
                <v-icon v-text="`mdi-plus`" left />
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rha_description_dlg"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        tile
        :disabled="rha_description_dlg_ldg"
        :loading="rha_description_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Tipo </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_description_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rha_description">
          <v-form
            v-on:submit.prevent
            ref="rha_description_form"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-spacer />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="Ingreso*"
                  v-model="rha_description.rha_description_id"
                  :items="rha_descriptions"
                  item-value="id"
                  :item-text="(i) => i.name"
                  :loading="rha_descriptions_ldg"
                  :rules="rules.required"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  small
                  color="warning"
                  @click.prevent="rhaDescriptionSubmit"
                >
                  Actualizar
                  <v-icon right> mdi-update </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rha_internment_dlg"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        tile
        :disabled="rha_internment_dlg_ldg"
        :loading="rha_internment_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Internamiento </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_internment_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rha_internment">
          <v-form v-on:submit.prevent ref="rha_internment_form" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-spacer />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="rha_internment.internment"
                  label="Descripción"
                  :rules="rules.required"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  small
                  color="warning"
                  @click.prevent="rhaInternmentSubmit"
                >
                  Actualizar
                  <v-icon right> mdi-update </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_PDF,
  URL_DOCUMENTS,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    ViewData,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      view_dialog_status_account_format: false,
      status_account_format_id: null,
      amount_id: 0,
      rha_status_account_id: 0,
      rules: rules(),
      page_route: null,
      url_pdf: URL_PDF,
      rha_id: this.$attrs.id,
      page_title: "Montos",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: true,
      data: {
        amount_2: 7000.0,
      },
      url_documents: URL_DOCUMENTS,
      types: [
        {
          id: 1,
          name: "AUTORIZADO",
        },
        {
          id: 2,
          name: "GASTADO",
        },
      ],
      amounts: [],
      documents: [],
      add_dialog: false,
      rha: {},
      amount: {
        created_by_id: null,
        updated_by_id: null,
        rha_id: this.$attrs.id,
        type: null,
        rha_amount_response_id: null,
        authorized_amount: "",
        sinester: "",
        letter_auth: "",
        cpt_id: null,
        icd_id: null,
        documents: [],
      },
      authorized_amount: 0,
      used_amount: 0,
      amount_responses: [],
      cpts: [],
      cpts_loading: false,
      icds: [],
      icds_loading: false,
      view_dialog: false,
      status_account_formats: [],
      show_cpt: null,
      show_icd: null,
      rha_descriptions: [],
      rha_descriptions_ldg: true,
      rha_description: null,
      rha_description_dlg_ldg: false,
      rha_description_dlg: false,
      rha_internment: null,
      rha_internment_dlg_ldg: false,
      rha_internment_dlg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    select_file(event, i) {
      this.amount.documents[i].file = event.target.files[0];
    },
    getAmounts() {
      this.loading = true;

      index("rha/amount", this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.amounts = response.data;
        this.total_rows = this.data.length;
        this.loading = false;
        this.add_dialog = false;
        this.addAmounts();
      });
    },
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        let cpt = true;
        if (this.amount.type == 1 && this.show_cpt && !this.amount.cpt_id) {
          cpt = false;
        }

        let icd = true;
        if (this.amount.type == 1 && this.show_icd && !this.amount.icd_id) {
          icd = false;
        }

        if (cpt || icd) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma la ${
                  this.store_mode ? "creación" : "edición"
                } del registro?`
              )
            )
            .then((response) => {
              if (response.isConfirmed) {
                storeUpdate(
                  "rha/amount",
                  this.login.token,
                  this.id,
                  this.amount,
                  this.login.id
                ).then((response) => {
                  this.$swal.fire(
                    msgAlert(
                      response.success ? "success" : "error",
                      response.message
                    )
                  );

                  if (response.success) {
                    this.amount = {
                      created_by_id: this.login.id,
                      updated_by_id: this.login.id,
                      rha_id: this.$attrs.id,
                      documents: [],
                    };
                    this.getRha();
                    this.getAmounts();
                  } else {
                    console.log(response.message);
                    this.loading = false;
                    this.add_dialog = true;
                  }
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert("error", "Se debe de registrar un CPT y/o ICD")
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    get_data_form() {
      let amount_submit = new FormData();
      for (var key in this.amount) {
        if (key !== "documents") {
          amount_submit.append(key, this.amount[key]);
        } else {
          for (var i = 0; i < this.amount.documents.length; i++) {
            for (var document_key in this.amount.documents[i]) {
              amount_submit.append(
                document_key + "_" + i,
                this.amount.documents[i][document_key]
              );
            }
          }
          amount_submit.append("total_documents", this.amount.documents.length);
        }
      }
      return amount_submit;
    },
    HandleDesactivate(id) {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy("rha/amount", this.login.token, id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.getAmounts()
                  : console.log(response.message);
              }
            );
          }
        });
    },
    handleDialogAdd() {
      this.add_dialog = !this.add_dialog;
    },
    openDialog() {
      this.amount = {
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rha_id: this.$attrs.id,
        cpt_id: this.rha.cpt_id,
        icd_id: this.rha.icd_id,
        sinester: this.rha.sinister_number,
        documents: [],
      };
      this.add_dialog = true;
    },
    editAmount(id) {
      show("rha/amount", this.login.token, id).then((response) => {
        this.amount = response;
        this.add_dialog = true;
        this.amount.cpt_id = this.rha.cpt_id;
        this.amount.icd_id = this.rha.icd_id;
      });
    },
    viewDialog(id) {
      show("rha/amount", this.login.token, id).then((response) => {
        this.amount = response;
        this.view_dialog = true;
      });
    },
    addDocumento() {
      this.amount.documents.push({
        id: null,
        desciption: "",
        rha_amount_document_type_id: "",
        active: true,
      });
    },
    deleteDocument(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.amount.documents[i].id === null)
              this.amount.documents.splice(i, 1);
            else this.amount.documents[i].active = false;
          }
        });
    },
    addAmounts() {
      this.authorized_amount = 0;
      this.used_amount = 0;
      for (var i = this.amounts.length - 1; i >= 0; i--) {
        if (this.amounts[i].type === 1) {
          if (this.amounts[i].rha_amount_response_id === 1)
            this.authorized_amount = Number(this.amounts[i].authorized_amount);
        } else this.used_amount = Number(this.amounts[i].used_amount);
      }
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getRha() {
      show("rha", this.login.token, this.rha_id).then((response) => {
        this.rha = response;

        this.status_account_format_id =
          this.rha.provider.status_account_format_id;

        this.show_cpt = true; // PARCHE TEMPORAL
        // this.show_cpt =
        //   this.login.role_id == 1 || (!this.rha.cpt_id && !this.rha.cpt_letter)
        //     ? true
        //     : false;
        this.show_icd =
          this.login.role_id == 1 || !this.rha.icd_id ? true : false;
      });
    },
    openSelectorStatusAccount(amount_id, rha_status_account_id) {
      this.amount_id = amount_id;
      this.rha_status_account_id = rha_status_account_id;
      this.view_dialog_status_account_format = true;
    },
    rhaDescriptionDlg() {
      this.rha_description = {
        id: this.rha.id,
        rha_description_id: this.rha.rha_description_id,
      };
      this.rha_description_dlg_ldg = false;
      this.rha_description_dlg = true;
    },
    rhaDescriptionSubmit() {
      if (this.$refs.rha_description_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma actualizar la información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.rha_description_dlg_ldg = true;

              Axios.post(
                URL_API + "/rhas/rha_description",
                this.rha_description,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.rha.rha_description_id = res.data.rha_description_id;
                  this.rha_description_dlg = false;
                } else {
                  console.log(res.data.message);
                }

                this.rha_description_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    rhaInternmentDlg() {
      this.rha_internment = {
        id: this.rha.id,
        internment: this.rha.internment ? this.rha.internment : "",
      };
      this.rha_internment_dlg_ldg = false;
      this.rha_internment_dlg = true;
    },
    rhaInternmentSubmit() {
      if (this.$refs.rha_internment_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma actualizar la información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.rha_internment_dlg_ldg = true;

              Axios.post(
                URL_API + "/rhas/internment",
                this.rha_internment,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.rha.internment = res.data.internment;
                  this.rha_internment_dlg = false;
                } else {
                  console.log(res.data.message);
                }

                this.rha_internment_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    catalog("rha_amount_responses", this.login.token).then((response) => {
      this.amount_responses = response;
    });
    catalog("status_account_formats", this.login.token).then((response) => {
      this.status_account_formats = response;
    });
    catalog("cpts", this.login.token).then((response) => {
      this.cpts = response;
      this.cpts_loading = false;
    });
    catalog("icds", this.login.token).then((response) => {
      this.icds = response;
      this.icds_loading = false;
    });
    index("documents/2", this.login.token, []).then((response) => {
      this.documents = response.data;
    });
    catalog("rha_descriptions", this.login.token).then((response) => {
      this.rha_descriptions = response;
      this.rha_descriptions_ldg = false;
    });

    this.getRha();

    this.getAmounts();
    this.amount.created_by_id = this.login.id;
    this.amount.updated_by_id = this.login.id;
  },
};
</script>